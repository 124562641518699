import styled from "styled-components";

export const Page = styled.div`
  max-width: 390px;
  width: 100%;
  margin: auto;
  text-align: center;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  margin: 30px 0;
`;

export const Button = styled.button`
  line-height: 20px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: inherit;
  box-sizing: border-box;
  padding: 15px 40px;
  min-width: 176px;
  height: 50px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  border-radius: 28px;
  outline: none;
  cursor: pointer;
  box-shadow: rgba(68, 104, 147, 0.15) 0px 5px 10px;
  border: none;
  display: inline-flex;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-around;
  user-select: none;
  background-color: rgb(80, 150, 232);
  color: rgb(255, 255, 255);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  margin: 16px;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  width: 100%;
`;

export const Heading = styled.h1`
  line-height: 34px;
  font-size: 28px;
  font-weight: 500;
  color: rgb(56, 74, 95);
  margin: 0px 0px 12px;
`;

export const Label = styled.label`
  line-height: 25px;
  font-size: 18px;
  font-weight: 300;
  color: rgb(56, 74, 95);
  width: 100%;
  text-align: left;
`;

export const Input = styled.input<{ error?: string }>`
  border: 2px solid
    ${(props) => (props.error ? "tomato" : "rgb(218, 223, 226)")};
  ::-webkit-input-placeholder {
    user-select: none;
  }
  :-ms-input-placeholder {
    user-select: none;
  }
  ::placeholder {
    user-select: none;
  }
  &:focus {
    border: 2px solid
      ${(props) => (props.error ? "tomato" : "rgb(218, 223, 226);")};
    outline: none;
  }
  font-family: Roboto, sans-serif;
  line-height: 25px;
  font-size: 18px;
  font-weight: 400;
  padding: 15px 10px;
  border-radius: 8px;
  flex-basis: 100%;
  box-sizing: border-box;
  color: rgb(133, 139, 147);
  -webkit-text-fill-color: rgb(133, 139, 147);
  opacity: 1;

  background-color: rgb(255, 255, 255);
  height: 48px;
  width: 100%;
`;

export const Caption = styled.span`
  color: black;
  font-family: "Roboto", sans-serif;
`;

export const Text = styled.span`
  line-height: 25px;
  font-size: 16px;
  font-weight: 300;
  color: rgb(56, 74, 95);
  font-family: Roboto, sans-serif;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 25px;
`;

export const ErrorText = styled(Caption)`
  bottom: 0;
  left: 8px;
  color: tomato;
  font-size: 16px;
  font-weight: 300;
`;

export const Link = styled.a`
  line-height: 25px;
  font-size: 18px;
  font-weight: 300;
  color: rgb(56, 74, 95);
  font-family: Roboto, sans-serif;
`;

export const Divider = styled.span`
  margin-bottom: 12px;
`;
