import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import InitiateResetScreen from "./screens/InitiateResetScreen";
import reportWebVitals from "./reportWebVitals";
import { ReactComponent as Logo } from "./assets/images/jointacademy_logo_pos.svg";
import "./i18n";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import NewPasswordScreen from "./screens/NewPasswordScreen";
import { Header, Page } from "./styledcomponents";
import SuccessScreen from "./screens/SuccessScreen";

const router = createBrowserRouter([
  {
    path: "/",
    element: <InitiateResetScreen />,
  },
  {
    path: "code",
    element: <NewPasswordScreen />,
  },
  {
    path: "success",
    element: <SuccessScreen />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Suspense>
    <React.StrictMode>
      <Page>
        <Header>
          <Logo height="40" />
        </Header>
        <RouterProvider router={router} />
      </Page>
    </React.StrictMode>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
