import type React from "react";
import styled from "styled-components";

const Badge = ({ children }: { children: React.ReactNode }) => {
  return <StyledBadge>{children}</StyledBadge>;
};

export default Badge;

const StyledBadge = styled.div`
  background: #edf4fc;
  padding: 4px 16px;
  font-size: 14px;
  border-radius: 14px;
  margin: 4px 0px 16px 0px;
`;
