import type React from "react";

import { Player } from "@lottiefiles/react-lottie-player";

import defaultSpinnerJson from "../assets/animations/default_spinner.json";

interface SpinnerProps {
  dataTestId?: string;
  small?: boolean;
  style?: React.CSSProperties;
}

const Spinner = ({ dataTestId, small, style }: SpinnerProps) => {
  let size = "150px";
  if (small) size = "70px";

  return (
    <div data-testid={dataTestId}>
      <Player
        autoplay
        loop
        src={defaultSpinnerJson}
        style={{ height: size, width: size, ...style }}
      />
    </div>
  );
};

export default Spinner;
