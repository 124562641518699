import React, { useEffect, useState } from "react";

import { useSearchParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  Container,
  Heading,
  Input,
  Label,
  StyledForm,
  Wrapper,
  Text,
  ErrorText,
  Divider,
} from "../styledcomponents";
import {
  getErrorMessage,
  newPasswordSchema,
} from "../utils/emailPasswordSchemas";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import Spinner from "../components/Spinner";
import Badge from "../components/Badge";
import SupportFooter from "../components/SupportFooter";
import LangDropdownOptions from "../components/LangDropdownOptions";

type JSONResponse = {
  success: boolean;
};

interface AuthError {
  error: string;
  status: string;
}

type FormValues = z.infer<typeof newPasswordSchema>;

const NewPasswordScreen = () => {
  const [searchParams] = useSearchParams();
  const [authRequest, setAuthRequest] = useState({
    loading: false,
    error: false,
    message: "",
  });
  const emailparam = searchParams.get("email");
  const languageParam = searchParams.get("lang");
  const codeParam = searchParams.get("confirmation_code");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (languageParam) {
      i18n.changeLanguage(languageParam);
    }
  }, [i18n, languageParam]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: "onBlur",
    resolver: zodResolver(newPasswordSchema),
    defaultValues: {
      email: emailparam || "",
      confirmation_code: codeParam || "",
    },
  });
  const onSubmit: SubmitHandler<FormValues> = async ({
    email,
    password,
    password_confirmation,
    confirmation_code,
  }) => {
    setAuthRequest({ loading: true, error: false, message: "" });

    try {
      const result = await fetch(
        `${process.env.REACT_APP_API_URL}/password/confirm`,
        {
          method: "POST",
          body: JSON.stringify({
            email,
            confirmation_code,
            password,
            password_confirmation,
          }),
        }
      );

      const response: JSONResponse = await result.json();

      if (response.success) {
        const search = languageParam
          ? `?email=${email}&lang=${languageParam}`
          : `?email=${email}`;

        navigate({
          pathname: "/success",
          search,
        });
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const response = error as AuthError;

      if (response) {
        let message = "errors.generic";

        switch (response.error) {
          case "invalid or malformed email address":
            message = "errors.invalid_email";
            break;
          case "too many attempts, try again later":
            message = "errors.too_many_attempts";
            break;
          case "invalid or expired confirmation code":
            message = "errors.invalid_code";
            break;
          default:
            message = "errors.generic";
            break;
        }

        setAuthRequest({
          loading: false,
          error: true,
          message: message,
        });
      }
    }
  };

  return (
    <Container>
      <Heading>{t("reset-password")}</Heading>
      <Badge>{`${t("step")} 2 / 2`}</Badge>
      <Text>{t("code.instructions")}</Text>
      <Divider />

      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        {!emailparam && (
          <Label>
            {t("email-label")}
            <Wrapper className="wrapper">
              <Input
                defaultValue={emailparam || ""}
                {...register("email", { required: true })}
                error={errors.email?.message}
                autoComplete={"username"}
                disabled={emailparam ? true : false}
              />

              {errors.email && (
                <ErrorText data-testid="validation-error">
                  {getErrorMessage(t, errors.email)}
                </ErrorText>
              )}
            </Wrapper>
          </Label>
        )}
        <Label>
          {t("confirmation_code-label")}
          <Wrapper className="wrapper">
            <Input
              defaultValue={codeParam || ""}
              {...register("confirmation_code", { required: true })}
              error={errors.confirmation_code?.message}
              disabled={codeParam ? true : false}
            />

            {errors.confirmation_code && (
              <ErrorText data-testid="validation-error">
                {getErrorMessage(t, errors.confirmation_code)}
              </ErrorText>
            )}
          </Wrapper>
        </Label>

        <Label>
          {t("password-label")}
          <Wrapper className="wrapper">
            <Input
              {...register("password", { required: true })}
              error={errors.password?.message}
              type="password"
              autoComplete={"new-password"}
            />

            {errors.password && (
              <ErrorText data-testid="validation-error">
                {getErrorMessage(t, errors.password)}
              </ErrorText>
            )}
          </Wrapper>
        </Label>
        <Label>
          {t("password_confirmation-label")}
          <Wrapper className="wrapper">
            <Input
              {...register("password_confirmation", { required: true })}
              error={errors.password_confirmation?.message}
              type="password"
              autoComplete={"new-password"}
            />

            {errors.password_confirmation && (
              <ErrorText data-testid="validation-error">
                {getErrorMessage(t, errors.password_confirmation)}
              </ErrorText>
            )}
          </Wrapper>
        </Label>
        {authRequest.error && <ErrorText>{t(authRequest?.message)}</ErrorText>}
        <Divider />
        <Button type="submit">
          {authRequest.loading ? <Spinner small /> : t("save")}
        </Button>
      </StyledForm>
      <SupportFooter />
      <LangDropdownOptions />
    </Container>
  );
};

export default NewPasswordScreen;
