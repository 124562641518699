import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as ArrowDown } from "../assets/images/arrowdown.svg";

const DropDownContainer = styled.div`
  width: 10.5em;
  margin: 0 auto;
`;

const DropDownHeader = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.8em;
  padding: 0.4em 2em 0.4em 1em;
  line-height: 25px;
  font-size: 14px;
  font-weight: 300;
  color: rgb(56, 74, 95);
  font-family: Roboto, sans-serif;
  background: #ffffff;
`;

const DropDownListContainer = styled("div")`
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
`;

const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  background: #ffffff;
  box-sizing: border-box;
  line-height: 25px;
  font-size: 14px;
  font-weight: 300;
  color: rgb(56, 74, 95);
  font-family: Roboto, sans-serif;
  &:first-child {
    padding-top: 0.8em;
  }
`;

const ListItem = styled.li`
  cursor: pointer;
  list-style: none;
  margin-bottom: 0.8em;
`;

const options = [
  { label: "English", value: "en", flag: "🇺🇸" },
  { label: "Svenska", value: "sv", flag: "🇸🇪" },
  { label: "French", value: "fr", flag: "🇫🇷" },
];

const LangDropdownOptions = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const languageParam = searchParams.get("lang");
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(languageParam);

  const bottom = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, [i18n, selectedLanguage]);

  useEffect(() => {
    if (isOpen) {
      bottom.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [isOpen]);

  const toggling = () => {
    setIsOpen(!isOpen);
  };

  const onOptionClicked = (option: { label: string; value: any }) => () => {
    const lang = option.value;
    const locale = i18n.language.split("-")[1];
    const fullLang = `${lang}${locale ? `-${locale}` : ""}`;
    setSelectedLanguage(fullLang);
    setIsOpen(false);
    setSearchParams(`?lang=${fullLang}`);
  };

  const selectedOption = options.find(
    (lan) => lan.value === i18n.language.split("-")[0]
  );

  return (
    <DropDownContainer>
      <DropDownHeader onClick={toggling}>
        {selectedOption
          ? `${selectedOption.flag} ${selectedOption.label}`
          : "Select language"}
        <StyledArrowDown $open={isOpen} />
      </DropDownHeader>
      {isOpen && (
        <DropDownListContainer>
          <DropDownList>
            {options.map((option, index) => (
              <ListItem onClick={onOptionClicked(option)} key={index}>
                {`${option.flag} ${option.label}`}
              </ListItem>
            ))}
          </DropDownList>
        </DropDownListContainer>
      )}
      <div ref={bottom} />
    </DropDownContainer>
  );
};

export default LangDropdownOptions;

const StyledArrowDown = styled(ArrowDown)<{ $open: boolean }>`
  transform: ${(props) => (props.$open ? "rotateZ(180deg)" : "rotateZ(0deg)")};
  transition-duration: 0.3s;
  margin-left: 5px;
  margin-bottom: 4px;
`;
