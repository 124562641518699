import type React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

type SupportMarket = {
  flag: string;
  email: string;
  phone: string;
  site: string;
};

type SupportMarkets = {
  [key: string]: SupportMarket;
};

const support: SupportMarkets = {
  US: {
    flag: "🇺🇸",
    email: "support@jointacademy.com",
    phone: "(815) 314-4390",
    site: "https://jointacademy.my.salesforce-sites.com/support/us/en/patient",
  },
  SE: {
    flag: "🇸🇪",
    email: "support@jointacademy.se",
    phone: "040-6550292",
    site: "https://jointacademy.my.salesforce-sites.com/support/se/sv/patient",
  },
  GB: {
    flag: "🇬🇧",
    email: "support@jointacademy.uk",
    phone: "",
    site: "https://jointacademy.my.salesforce-sites.com/support/gb/en/patient/",
  },
  FR: {
    flag: "🇫🇷",
    email: "support@jointacademy.fr",
    phone: "",
    site: "https://jointacademy.my.salesforce-sites.com/support/fr/fr/patient",
  },
};

const SupportFooter = () => {
  const { i18n, t } = useTranslation();

  const locale = i18n.language.toUpperCase().split("-")[1];
  const validLocale = Object.keys(support).includes(locale);

  if (validLocale) {
    const { email, phone } = support[locale];
    return (
      <StyledSupportFooter>
        {`${t("need-help1")} `}
        <StyledLink to={`mailto:${email}`}>{`${email} `}</StyledLink>
        {phone && (
          <>
            {`${t("need-help2")} `}
            <StyledLink to={`tel:${phone}`}>{`${phone} `}</StyledLink>
          </>
        )}
      </StyledSupportFooter>
    );
  } else {
    return (
      <StyledSupportFooter>
        {`${t("need-help1")} ${t("need-help2")}`}
        {Object.keys(support).map((key) => {
          const { email, phone, flag } = support[key];
          return (
            <div key={key}>
              {`${flag} `}
              <StyledLink to={`mailto:${email}`}>{`${email} `}</StyledLink>
              {` / `}
              <StyledLink to={`tel:${phone}`}>{`${phone} `}</StyledLink>
            </div>
          );
        })}
      </StyledSupportFooter>
    );
  }
};

export default SupportFooter;

const StyledSupportFooter = styled.div`
  margin: 32px 0px 16px 0px;
  font-size: 14px;
  font-weight: 300;
  color: rgb(56, 74, 95);
`;

const StyledLink = styled(Link)`
  color: rgb(56, 74, 95);
`;
