import { useState } from "react";

import { useSearchParams, useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import {
  Container,
  Heading,
  StyledForm,
  Label,
  Wrapper,
  Input,
  Button,
  Text,
  ErrorText,
  Divider,
} from "../styledcomponents";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  getErrorMessage,
  passwordResetSchema,
} from "../utils/emailPasswordSchemas";
import { z } from "zod";
import Spinner from "../components/Spinner";
import LangDropdownOptions from "../components/LangDropdownOptions";
import Badge from "../components/Badge";
import SupportFooter from "../components/SupportFooter";

type JSONResponse = {
  success: boolean;
};

interface AuthError {
  error: string;
  status: string;
}

type FormValues = z.infer<typeof passwordResetSchema>;

const EmailInputScreen = () => {
  const [authRequest, setAuthRequest] = useState({
    loading: false,
    error: false,
    message: "",
  });
  const [searchParams] = useSearchParams();
  const emailparam = searchParams.get("email");
  const languageParam = searchParams.get("lang");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: "onBlur",
    resolver: zodResolver(passwordResetSchema),
    defaultValues: {
      email: emailparam || "",
    },
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setAuthRequest({ loading: true, error: false, message: "" });

    const { email } = data;

    try {
      const result = await fetch(
        `${process.env.REACT_APP_API_URL}/password/reset`,
        {
          method: "POST",
          body: JSON.stringify({ email }),
        }
      );

      const response: JSONResponse = await result.json();

      if (response.success) {
        const url = languageParam
          ? `/code?email=${encodeURIComponent(email)}&lang=${languageParam}`
          : `/code?email=${encodeURIComponent(email)}`;
        navigate(url);
      } else {
        throw response;
      }
    } catch (error: unknown) {
      const response = error as AuthError;

      if (response) {
        let message = "errors.generic";

        switch (response.error) {
          case "invalid or malformed email address":
            message = "errors.invalid_email";
            break;
          case "too many attempts, try again later":
            message = "errors.too_many_attempts";
            break;
          default:
            message = "errors.generic";
            break;
        }

        setAuthRequest({
          loading: false,
          error: true,
          message: message,
        });
      }
    }
  };

  return (
    <Container className="InitiateReset">
      <Heading>{t("reset-password")}</Heading>
      <Badge>{`${t("step")} 1 / 2`}</Badge>
      <Text>{t("instructions")}</Text>
      <Divider />
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Label>
          {t("email-label")}
          <Wrapper className="wrapper">
            <Input
              defaultValue={emailparam || ""}
              autoFocus={true}
              error={errors.email?.message}
              autoComplete={"username"}
              {...register("email", { required: true })}
              disabled={emailparam ? true : false}
            />
            {errors.email && (
              <ErrorText data-testid="validation-error">
                {getErrorMessage(t, errors.email)}
              </ErrorText>
            )}
            {authRequest.error && (
              <ErrorText>{t(authRequest?.message)}</ErrorText>
            )}
          </Wrapper>
        </Label>

        <Button type="submit">
          {authRequest.loading ? <Spinner small /> : t("send")}
        </Button>
      </StyledForm>
      <SupportFooter />
      <LangDropdownOptions />
    </Container>
  );
};

export default EmailInputScreen;
