import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import SupportFooter from "../components/SupportFooter";
import { Container, Heading, Text, Link } from "../styledcomponents";
import { ReactComponent as CheckMark } from "../assets/images/checkmark.svg";
import LangDropdownOptions from "../components/LangDropdownOptions";
import styled from "styled-components";

const SuccessScreen = () => {
  const [searchParams] = useSearchParams();

  const { t, i18n } = useTranslation();
  const languageParam = searchParams.get("lang");
  const email = searchParams.get("email");
  // an alternative structure to check individual matches
  const mobileBrowser =
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/iPhone/i);

  useEffect(() => {
    if (languageParam) {
      i18n.changeLanguage(languageParam);
    }
  }, [i18n, languageParam]);

  return (
    <Container>
      <StyledCheckMark />
      <Heading>{t("success.heading")}</Heading>
      <Text>{t("success.message")}</Text>
      {mobileBrowser && (
        <>
          <br />
          <Text>
            {`${t("success.open-app")} `}
            <Link
              href={`${
                process.env.REACT_APP_APP_LINK
              }?email=${encodeURIComponent(email || "")}#/page/login_email`}
            >
              {t("success.open-app-link")}
            </Link>
          </Text>
        </>
      )}
      <SupportFooter />
      <LangDropdownOptions />
    </Container>
  );
};

export default SuccessScreen;

const StyledCheckMark = styled(CheckMark)`
  margin-bottom: 20px;
`;
